import { Component, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlightIcons, Icon, Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyModalComponent, ModalType } from '@garmin-avcloud/avcloud-ui-common/modal';
import { ToastService } from '@garmin-avcloud/avcloud-ui-common/toast';
import { RouteComputedLeg, RouteComputedLegLocationType } from '@generated/flight-orchestrator-service';
import { FlightRouteControllerService, LatLonRequest, RouteComputedLegBearingRef } from '@generated/flight-route-service';
import { State } from '@shared/enums/loading-state.enum';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { UnitConverterPipe } from '@shared/pipes/unit-converter/unit-converter.pipe';
import { FlightOrchestratorUserSettingsService } from '@shared/services/flight-orchestrator/user-settings/flight-orchestrator-user-settings.service';
import { LatLonUtils } from '@shared/utils/lat-lon-utils';
import { Observable, of, switchMap } from 'rxjs';
import { SettingsWaypointsService } from '../settings-waypoints.service';

const UNSUPPORTED_FORMAT_MESSAGE = 'Unsupported format';

@Component({
  selector: 'pilot-settings-waypoints-edit-modal',
  templateUrl: './settings-waypoints-edit-modal.component.html',
  styleUrls: ['./settings-waypoints-edit-modal.component.scss']
})

export class SettingsWaypointsEditModalComponent {
  @ViewChild('editModal') editModal: FlyModalComponent;
  @Input() title = 'Add User Waypoint';
  @Output() readonly loadWaypointsList: EventEmitter<any> = new EventEmitter();

  private readonly flightOrchestratorUserSettingsService: FlightOrchestratorUserSettingsService = inject(FlightOrchestratorUserSettingsService);
  private readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly toastService: ToastService = inject(ToastService);
  private readonly settingsWaypointService = inject(SettingsWaypointsService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly flightRouteService: FlightRouteControllerService =inject(FlightRouteControllerService);
  private readonly unitConverterPipe = new UnitConverterPipe();

  readonly wptForm = this.settingsWaypointService.wptForm;
  readonly latLonForm = this.settingsWaypointService.latLonForm;
  readonly brgDisForm = this.settingsWaypointService.brgDisForm;
  readonly radRadForm = this.settingsWaypointService.radRadForm;

  ModalType = ModalType;
  Icons = Icons;
  State = State;
  waypointEditModalState: State = State.Loading;
  addingNewWaypoint: boolean = false;
  showWaypoint: RouteComputedLeg | null;
  showAirport: AirportSearchResult | null;
  readonly FlightIcons = FlightIcons;
  icon: Icon;
  tabIndex: number = 0;
  tabs = [
    { label: 'Lat/Lon' },
    { label: 'Rad/Dist' },
    { label: 'Rad/Rad' }
  ];

  errorMap = this.settingsWaypointService.errorMap;

  waypoint: SettingsWaypointsWaypointModel;

  showModal(): void {
    this.editModal.show();
  }
  hideModal(): void {
    this.editModal.hide();
  }

  loadWaypoints(waypoint: SettingsWaypointsWaypointModel | any = {}): void {
    this.wptForm.reset();
    this.latLonForm.reset();
    this.brgDisForm.reset();
    this.radRadForm.reset();
    this.addingNewWaypoint = waypoint.id == null;
    const defaultWaypoint = { displayOnMap: true };
    this.waypoint = structuredClone((Object.keys(waypoint).length === 0) ? defaultWaypoint : waypoint);
    //default to show on map
    if (this.addingNewWaypoint) {
      this.tabIndex = 0;
      this.title = 'Add User Waypoint';
    } else {
      this.title = 'Edit User Waypoint';
      if (waypoint.waypointJson['userWaypointType'] === 1) {
        this.tabIndex = 0;
      } else if (waypoint.waypointJson['userWaypointType'] === 2) {
        this.tabIndex = 1;
      } else {
        this.tabIndex = 2;
      }
    }

    this._loadForm(this.waypoint);
    this.waypointEditModalState = State.Loaded;
    this.showModal();
  }

  saveWaypoints(): void {
    if (this.wptForm.valid) {
      this._getFormWaypoints().pipe(switchMap((request) => this.addingNewWaypoint ?
        this.flightOrchestratorUserSettingsService.postWaypoints(request) : this.flightOrchestratorUserSettingsService.putWaypoints(request)),
      takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => this._afterSaveWaypoints(),
        error: (error) => this._afterSaveWaypointsError(error)
      });
    } else {
      this.wptForm.markAllAsTouched();
      this.toastService.createToast('Invalid waypoint to save.', 'error');
    }
  }

  deleteWaypoints(): void {
    if (this.waypoint.id != null) {
      this.flightOrchestratorUserSettingsService.deleteWaypoints([this.waypoint.id]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => this._afterSaveWaypoints(),
        error: (error) => this._afterSaveWaypointsError(error)
      });
    }
  }

  private _afterSaveWaypoints(): void {
    this.cdRef.detectChanges();
    this.hideModal();
    this.loadWaypointsList.emit();
  }

  private _afterSaveWaypointsError(error: Response): void {
    this.toastService.createToast(error.statusText, 'error');
    for (const each of Object.values(this.wptForm.controls)) {
      each.updateValueAndValidity();
    }
  }

  _loadForm(waypoint: SettingsWaypointsWaypointModel | any = {}): void {
    this.wptForm.controls.name.setValue(waypoint.name);
    this.wptForm.controls.comment.setValue(waypoint.waypointJson?.comment);
    this.wptForm.controls.displayOnMap.setValue(waypoint.displayOnMap);
  }

  // Save waypoint values to backend
  private _getFormWaypoints(): Observable<SettingsWaypointsWaypointModel> {
    const request: any = this.waypoint ?? {}; // begins with original waypoint object
    request.waypointJson = (typeof request.waypointJson === 'string') ? JSON.parse(request.waypointJson) : (request.waypointJson ?? {});
    request.name = this.wptForm.value.name;
    request.waypointJson.comment = this.wptForm.value.comment;
    request.displayOnMap = this.wptForm.value.displayOnMap;
    request.waypointJson.userWaypointType = this.tabIndex + 1;
    if (this.waypoint.waypointJson['uuid'] != null) {
      request.waypointJson.uuid = this.waypoint.waypointJson['uuid'];
    }
    if (this.tabIndex === 0) {
      request.lat = this.formatLatLonForSave(this.latLonForm.controls.latDeg.value, this.latLonForm.controls.latMin.value, this.latLonForm.controls.latSec.value);
      request.lon = this.formatLatLonForSave(this.latLonForm.controls.lonDeg.value, this.latLonForm.controls.lonMin.value, this.latLonForm.controls.lonSec.value);
      request.waypointJson.format = this.latLonForm.value.format;
      request.waypointJson.referenceWaypoint1Identifier = null;
      request.waypointJson = JSON.stringify(request.waypointJson);
      return of(request);
    } else if (this.tabIndex === 1) {
      const latLonRequest: LatLonRequest = {
        waypoint1 : {
          identifier: this.brgDisForm.controls.identifier.value,
          lat: this.brgDisForm.controls.lat.value,
          lon: this.brgDisForm.controls.lon.value,
          angle: Number(this.brgDisForm.controls.rad.value),
          distance: Number(this.brgDisForm.controls.dist.value),
          bearingRef: RouteComputedLegBearingRef.MAGNETIC_NORTH
        }};

      if ([RouteComputedLegLocationType.VOR, RouteComputedLegLocationType.NDB].includes(this.brgDisForm.controls.locationType.value as RouteComputedLegLocationType)) {
        latLonRequest.waypoint1.bearingRef = RouteComputedLegBearingRef.RADIAL;
        latLonRequest.waypoint1.countryCode = this.brgDisForm.controls.qualifier.value;
      }

      request.waypointJson.referenceWaypoint1Identifier = this.brgDisForm.controls.identifier.value;
      request.waypointJson.referenceWaypoint1Qualifier = this.brgDisForm.controls.qualifier.value;
      request.waypointJson.referenceWaypoint1LocationType = this.brgDisForm.controls.locationType.value;
      request.waypointJson = JSON.stringify(request.waypointJson);
      return this.flightRouteService.latLongSearchPost(latLonRequest).pipe(switchMap((response) => {
        if (response.lat != null && response.lon != null) {
          request.lat = response.lat;
          request.lon = response.lon;
        }
        return of(request);
      }));
    } else {
      const latLonRequest = {
        waypoint1 : {
          identifier: this.radRadForm.controls.identifier1.value,
          lat: this.radRadForm.controls.lat1.value,
          lon: this.radRadForm.controls.lon1.value,
          angle: Number(this.radRadForm.controls.rad1.value)
        },
        waypoint2: {
          identifier: this.radRadForm.controls.identifier2.value,
          lat: this.radRadForm.controls.lat2.value,
          lon: this.radRadForm.controls.lon2.value,
          angle: Number(this.radRadForm.controls.rad2.value)
        }};

      request.waypointJson.referenceWaypoint1Identifier = this.radRadForm.controls.identifier1.value;
      request.waypointJson.referenceWaypoint1Qualifier = this.radRadForm.controls.qualifier1.value;
      request.waypointJson.referenceWaypoint1LocationType = this.radRadForm.controls.locationType1.value;
      request.waypointJson.referenceWaypoint2Identifier = this.radRadForm.controls.identifier2.value;
      request.waypointJson.referenceWaypoint2Qualifier = this.radRadForm.controls.qualifier2.value;
      request.waypointJson.referenceWaypoint2LocationType = this.radRadForm.controls.locationType2.value;
      request.waypointJson = JSON.stringify(request.waypointJson);
      return this.flightRouteService.latLongSearchPost(latLonRequest).pipe(switchMap((response) => {
        if (response.lat != null && response.lon != null) {
          request.lat = response.lat;
          request.lon = response.lon;
        }
        return of(request);
      }));
    }
  }

  private formatLatLonForSave(deg: string, min: string, sec: string): number | undefined {
    if (deg != null) {
      const formattedLat = LatLonUtils.convertToDegrees(deg, min, sec);
      if (formattedLat == null) {
        this.toastService.createToast(UNSUPPORTED_FORMAT_MESSAGE, 'error');
      }
      return formattedLat;
    }
    return;
  }

  onDeleteWaypoint(): void {
    this.showAirport = null;
    this.showWaypoint = null;
  }
}
